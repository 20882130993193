<template>
  <div id="app">
    <header>
      <div class="bl-header hasFixed">
        <div class="container header">
          <h1 class="logo">
            <RouterLink to="/">海南小游科技有限公司</RouterLink>
          </h1>
          <ul class="navs">
            <li class="home"><RouterLink to="/">首页</RouterLink></li>
            <!-- <li class="more">
              <RouterLink to="/more">更多游戏</RouterLink>
            </li> -->
            <li class="about">
              <RouterLink to="/contact">联系我们</RouterLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="line"></div>
    </header>
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
    <footer>
      <div class="footer">
        <div class="container">
          <p>版权所有：海南小游科技有限公司</p>
          <p v-if="pathName">
            ICP备案号：<a href="https://beian.miit.gov.cn/" target="_blank"
              >琼ICP备2020003567号
            </a>
          </p>
          <p>
            对本网站产品、服务、信息、材料等一切内容享有全部知识产权。
          </p>
          <p>
            任何第三方不得利用本网站内容获取收益或以任何方式侵犯我公司权利，否则我公司将依法追究其法律责任。
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pathName: true
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val)
        if (val.name == 'game') {
          this.pathName = false
        } else {
          this.pathName = true
        }
      },
      deep: true
    }
  },
  created () {}
}
</script>

<style lang="less" scoped>
.footer {
  padding: 20px;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 18px;
  p {
    margin-top: 10px;
    a {
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .footer_text {
    display: flex;
    justify-content: space-between;
    p {
      width: 550px;
      text-align: left;
    }
  }
}

.hasFixed {
  position: fixed;
  left: 0;
  right: 0;
}
.line {
  height: 74px;
}
.bl-header {
  z-index: 999;
  background-blend-mode: normal, color-burn;
  background-color: #fff;
  // 设计背景模糊处理
  // backdrop-filter: saturate(180%) blur(6px);
  // background-color: var(--header-background);
  // // border-bottom: 2px solid #fff;
  .header {
    display: flex;
    // height: 74px;
    justify-content: space-between;
    h1 {
      margin: auto;
      margin-left: 0;
      // height: 80px;
    }
    .logo {
      margin-left: 120px;
      width: 200px;
      a {
        display: block;
        width: 100%;
        height: 74px;
        text-indent: -9999px;
        background: url(~@/assets/img/logo.png) no-repeat center 0px / contain;
      }
    }
    .navs {
      width: 844px;
      display: flex;
      justify-content: center;
      // justify-content: space-around;
      li {
        display: block;
        // flex: 1;
        text-align: center;
        margin: 0 50px;
        a {
          line-height: 74px;
          height: 74px;
          display: block;
          font-family: 'SourceHanSansCN-Medium';
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #737373;
          &.router-link-exact-active {
            color: @blColor;
            background-color: #fff;
            border-bottom: 5px solid @blColor;
          }
        }
        &:hover {
          a {
            color: @blColor;
            &.router-link-exact-active {
              color: @blColor;
            }
          }
        }
      }
    }
  }
}

.slide-fade {
  position: absolute;
  left: 0;
  right: 0;
}
.slide-fade-enter-active {
  transition: all 1.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(2, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  left: 0;
  right: 0;
  transform: translateY(50px);
  opacity: 0;
}
</style>
